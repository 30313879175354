import React from "react"
import { useMatch } from "@reach/router"
import NewsDetail from './news-details';
import CommonTemplate from './common-template';
import PropertyDetails from './property-details';
import CareersDetails from './careers-details-template';

import get from 'lodash/get'
import {useStaticQuery, graphql } from 'gatsby'


export default ({data},props) => {
  
  const careersdetail = useMatch("/about/careers/:item/")
  const newsdetail = useMatch("/about/blog/:item/")
  const salematch = useMatch('/property-for-sale/:slug');
  const rentmatch = useMatch('/property-to-rent/:slug');
  const Page = data.strapiArticles
  const Modules = data.strapiArticles.Modules
  const GlobalConfig = data.strapiGlobalConfig
  const TeamMember = data.strapiArticles.Team_Members
  const TeamSlider = data.allStrapiTeams
  const JobOpenings = data.allStrapiCareers
  const OfficeList = data.allStrapiOffices
  const VideosList = data.allStrapiOurVideos

  if(salematch !=null) {
    var propertyURL = salematch.slug
    var propID = propertyURL.split('-').pop()
    propID = propID.replace("/","")
    return(
    <PropertyDetails Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }

  else if(rentmatch !=null) {
    var propertyURL = rentmatch.slug
    var propID = propertyURL.split('-').pop()
    propID = propID.replace("/","")
    return (
    <PropertyDetails Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }

  else if(careersdetail !=null) {
    var propertyURL = careersdetail.slug
    return (
    <CareersDetails detailpage={careersdetail} JobOpenings={JobOpenings} />
    )
  }
  else if(newsdetail !=null) {
    return(
      <NewsDetail detailpage={newsdetail.item}/>
    )
  } else {
    return (
      <CommonTemplate Page={Page} Modules={Modules} GlobalConfig={GlobalConfig} TeamMember ={TeamMember} TeamSlider={TeamSlider} JobOpenings={JobOpenings} 
      OfficeList={OfficeList} VideosList={VideosList} />   
    )
  }


}


export const pageQuery = graphql`
  query DeafultQuery($id: String!) {
  strapiArticles(strapiId: {eq: $id}) {
    Alias
    id
    Meta_Description
    Meta_Title
    Pagename
    Layout
    Custom_CSS_Class
    Select_Popular_Search
    Modules {
      Banner_CTA_1_Label
      Banner_CTA_2_Label
      Banner_1_CTA_Hash
      Banner_2_CTA_Hash
      Banner_2_Custom_Link
      Banner_1_Custom_Link
      Button2_Class
      Banner_Title
      Embed_Video_URL
      Upload_Video {
        internal {
          description
        }
      }
      Show_Review
      Show_Search
      Table_Content
      Content_Column_Left
      Content_Column_Right
      Intro_Content
      Intro_Title
      Awards_Details {
        Content
        Year
        id
      }
      Child_Pages {
        Child_Page_Label
        Child_Page_Link {
          URL
        }
        Child_Page_Custom_Link
      }
      Static_Intro_Title
      Static_Intro_Content
      Static_Banner_Title
      Static_Banner_Video_URL
      Static_Banner_Video_Caption
      Static_Banner_Image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2048) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      }        
      Landing_Video_Image {
        internal {
          description
        }
        childImageSharp {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Landing_Video_URL
      Video_Background_Image {
            internal {
              description
            }
            childImageSharp {
              fluid(quality: 90, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
      }
      Banner_CTA_1_URL {
        URL
        main_parent
      }
      Banner_CTA_2_URL {
        URL
        main_parent
      }
      Valuation_Title
      Valuation_Content
      Valuation_Left_Box_Content
      Valuation_Left_Box_Cta_Label
      Valuation_Left_Box_Image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      Valuation_Left_Box_Title
      Valuation_Right_Box_Content
      Valuation_Right_Box_Title
      Valuation_Right_Box_Cta_Label
      Valuation_Left_Box_CTA_Link
      Valuation_Right_Box_CTA_Link
      Valuation_Right_Box_Image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      History_Top {
        Caption
        Year
        Image {
          childImageSharp {
              fixed(width: 248, height: 164) {
                ...GatsbyImageSharpFixed
              }
          }

        }
      }
      History_Bottom {
        Caption
        Year
        Image {
          childImageSharp {
            fixed(width: 248, height: 164) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
     
      Tiles {
        CTA_Label
        Content
        Tile_Position
        Title
        Embed_Video_URL
        Tile_Image {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
        }
        Tile_URL {
          URL
        }
      }
      Stats {
        Caption
        Value
        Value_Type
      }
      Statistics_Title
      Select_Module
      Select_Calculator
      Banner_title
      Banner_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2048) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Ctas {
        Label
        ctlink {
          URL
          Secondary_URL
        }
      }
    }
    Team_Members {
          id
          Designation
          Email
          Calendly_Link
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    }
  }
  strapiGlobalConfig {
      Footer_Box_Content
      Footer_Box_Title
      Footer_Office_Address_Left
      Footer_Office_Address_Right
      Get_In_Touch_Content
      Send_US_Form_Content
      Popular_Search_Static_Common
      Popular_Search_Static_Rentals
      Popular_Search_Static_Sales
  }

  allStrapiTeams {
    edges {
      node {
        strapiId
        Name
        URL
        Designation
        Email
        Phone
        Embed_Video_URL
        Calendly_Link
        Image {
          internal {
            description
          }
        }
      }
    }
  }

  allStrapiCareers {
    edges {
      node {
        Caption
        Job_Opening
        URL
        Tile_Image {
          childImageSharp {
            fluid(maxWidth: 388) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }

  allStrapiOurVideos {
    edges {
      node {
        strapiId
        Embed_Video_URL
        Title
        Select_Category
        imagetransforms {
          Video_Image_Transforms
        }
        Video_Image {         
          internal {
            description
          }
        }
      }
    }
  }
  
  allStrapiOffices {
    edges {
      node {
        strapiId
        Title
        URL
        Tile_Image {
          internal {
            description
          }
        }
        imagetransforms {
          Tile_Image_Transforms
        }
        Address
        Phone_Number
        Review_Key
      }
    }
  }
  }
`
