import React, { useState, useRef, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import PersonVideo from '@components/person-video/person-video';
import { Link } from "gatsby";
import { Logo } from '@components/icon/icon';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import ScrollAnimation from 'react-animate-on-scroll';
import PlayIcon from '@components/play-icon/play-icon';
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import ReactPlayer from 'react-player';

import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import ImgBox from '@components/img-box/img-box'

const GET_ALL_AREAS = gql`
query GETAREAS{
    areaGuides {
    id
    Title
    URL
    Tile_Image {
      url
    }
    Embed_Video_URL
    imagetransforms
  }
}
`;



function AreaList() {
    const { loading, error, data } = useQuery(GET_ALL_AREAS);
    const [showVideo, setShowVideo] = React.useState(false);
    const [videoindex, setVideoindex] = React.useState('default');
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const vidRef = useRef(null);
    let AOS;
  useEffect(() => {
      const AOS = require("aos");
      AOS.init({
          once: true
      });
  }, [])

  useEffect(() => {
      if (AOS) {
          AOS.refresh();
      }
  });
    const [isPlay,setPlay] = React.useState(false);
      const playVideo = (video_index, video_id) => {
        setVideoindex(video_index)   
        setShowVideo(true)
      }
      const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': url,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
      }
  
  
    if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);   
        
    return (
        <>
        <div className="static-person-video area-section team-listing-intro listing-main-area">
            <Container>  
                <div className="slider-holder row">
                    {data.areaGuides.map((node, index) => {
                        var incekey =  index+1;
                        var delaying = incekey+'00';  
                        if(node.Embed_Video_URL !=null && node.Embed_Video_URL != '') {
                            var videoid = getVideoId(node.Embed_Video_URL);
                        }                
                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Tile_Image_Transforms) {
                            processedImages = node.imagetransforms.Tile_Image_Transforms;
                        }	                                            
                        return <>
                        <div className = "col-lg-4 col-md-6 block-wrap">
                        <ScrollAnimation offset={!isMobile?1000:''} delay={delaying} animateIn="animate__fadeInUp" animateOnce={true}>                                  
                            <div className="person-video">
                            <div className="vide-wrapper video-box">
                            <Link to={node?.URL ? node.URL+'/' : '#'}>
                            <ImageTransform imagesources={node.Tile_Image.url} renderer="srcSet" imagename="area-guides.Tile_Image.commontile"attr={{ alt: node.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={node.id}/>
                            </Link>
                            {showVideo && videoindex== index &&
                            <>
                            <div className="videoBTNClose">
                                <a onClick={e => {setPlay(false);setShowVideo(false);setVideoindex('default');}}></a>
                            </div>
                                    
                                  <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(node.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} frameborder="0" muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false);setVideoindex('default'); }} width='100%' height='100%' />

                                    </>
                            }
                            </div>
                            <Link to={node?.URL ? node.URL+'/' : '#'}><h4>{node.Title}</h4></Link>
                            {node.Embed_Video_URL !=null && node.Embed_Video_URL != '' && videoindex != index &&
                                <a href="javascript:;" onClick = { (e) => {playVideo(index, videoid.id)}}>
                                <PlayIcon />
                                </a>
                            }
                            
                            </div>
                            </ScrollAnimation>
                        </div>
                        </>
                    })}
                        
                </div>
            </Container>
        </div>
        </>
    )

                }
export default AreaList
