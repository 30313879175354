import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './styles/_index.scss';
import MortgageCalc from "../calculators/mortgagecalc/index";
import StampDutyCalc from "../calculators/stampdutycalc/index";


const PropertyCalculatorSection = (property) => {
  return (
    <div className='property-calculator-section'>
        <Container>
            <Row>
                <Col lg="6" className='mort_cal'>
                    <h3>Stamp Duty Calculator</h3>
                    <p>Calculate how much stamp duty you will have to pay based on the purchase price of the property.</p>
                    <StampDutyCalc imageShow={false} propertyDetails={true} property={property}/>
                </Col>
                <Col lg="6" className='stamp_cal'>
                    <h3>Mortgage Calculator</h3>
                    <MortgageCalc imageShow={false} propertyDetails={true} property={property}/>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default PropertyCalculatorSection