import React, {useEffect} from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import $ from 'jquery'
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import { ArrowLeft, Bedroom, Bathroom, Reception } from '@components/icon/icon';
import GetOffice from './get-office';

import { SaveProperty, useUserObjectState, useAuthState } from "@starberry/myaccount-website-utils";

const PropertyTop = (props) => {



    const { state, services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
  
    useEffect(() => {
        if (isAuthenticated) {
        // on page load get the users saved objects
            services.getUserobject({ type: "property", state:true })
        }
      }, [])
    


    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top-50
        }, 2000);
    }
    const handleBack = () => {
        $("html, body").scrollTop(0);
    }
    var price= Math.round(props.price)
    var BackURL = ''
    if(props.searchtype === "sales") {
        BackURL = '/property/for-sale/in-london'
    } else {
        BackURL = '/property/to-rent/in-london'
    }
    return (
        <div className="property-top">
            <Container>
                <Row className="justify-content-between property-row">
                    <Col lg="7">
                        <h1>
                            <Link to={`${BackURL}/`} className="left-arrow" onClick={handleBack} onKeyPress={handleBack}><ArrowLeft /><span>Back to Property Listings</span>
                            </Link>
                            {props.address}
                        </h1>
                        <h2 className="pricetop">£{price.toLocaleString()} <span>
                            {props.searchtype === 'lettings'?<span>pcm <Link to="/property-services/rent/tenant-fees/">(fees apply)</Link></span>:''}
                            {props.pricequalifier && props.searchtype !== 'lettings'?'('+props.pricequalifier+')':''}</span></h2>
                    </Col>
                 
                    <Col lg="3">
                        <a onClick={() => gotohash('#book-viewing')}  href="javascript:;" className="btn d-none d-lg-block">Book a Viewing</a>
                    </Col>
                </Row>
                <Row  className="justify-content-between property-row">
                    <Col lg="7">
                        <Row>
                          
                            {/*<Col md="3">
                                <h6>Price:</h6>
                                <h5>£{price.toLocaleString()}</h5>
                            </Col>*/}
                           
                            {props.bedroom > 0 && props.bedroom && 
                            <Col md="3" xs="4">
                                <div className="icon-detail-top">
                                    <h6><Bedroom/></h6>
                                    <h5>{props.bedroom} {props.bedroom > 1 ? 'Bedrooms' : 'Bedroom'}</h5>
                                </div>
                            </Col>
                            }
                            {props.bathroom > 0 && props.bathroom &&
                            <Col md="3" xs="4">
                                <div className="icon-detail-top">
                                    <h6><Bathroom/></h6>
                                    <h5>{props.bathroom} {props.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</h5>
                                </div>
                            </Col>
                            }
                            {props.reception > 0 && props.reception &&
                            <Col md="3" xs="4">
                                <div className="icon-detail-top">
                                    <h6><Reception/></h6>
                                    <h5>{props.reception} {props.reception > 1 ? 'Receptions' : 'Reception'}</h5>
                                </div>
                            </Col>
                            }

                        </Row>
                    </Col>
                    <Col lg="3" className="d-none d-lg-block">
                        <GetOffice officecrmid={props.officecrmid}/>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PropertyTop
