import React from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'react-bootstrap';

/**
 * Assets
 */

import './styles/_index.scss';
import { Link } from 'gatsby';

const ContactQueryForm = () => {
    return (
        <Formik
            initialValues={{ name: '', email: '', phone: '', picked: "I’m looking to sell or let my property", message: '' }}
            validate={values => {
                const errors = {};
                if (!values.name) {
                    errors.name = 'Required';
                }
                if (!values.email) {
                    errors.email = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className="contact-form">
                    <h4>How can we help you?</h4>
                    <Form.Group>
                        <label className="radio-label">
                            <Field type="radio" name="picked" value="I’m looking to sell or let my property" checked={values.picked === "I’m looking to sell or let my property"} />
                            <span className="radio-ico"></span>
                            I’m looking to sell or let my property
                        </label>
                        <label className="radio-label">
                            <Field type="radio" name="picked" value="I’m interested in my property(s) being managed" />
                            <span className="radio-ico"></span>
                            I’m interested in my property(s) being managed 
                        </label>
                        <label className="radio-label">
                            <Field type="radio" name="picked" value="Something else" />
                            <span className="radio-ico"></span>
                            Something else
                        </label>
                    </Form.Group>
                    <Form.Group>
                        <Field name="name" type="text" placeholder="Your Name" value={values.name} />
                    </Form.Group>
                    <Form.Group>
                        <Field name="email" type="email" placeholder="Your Email Address" value={values.email} />
                    </Form.Group>
                    <Form.Group>
                        <Field name="phone" type="text" placeholder="Your Telephone Number" value={values.phone} />
                    </Form.Group>
                    <Form.Group>
                        <Field name="message" component="textarea" type="textarea" placeholder="Write your message here..." value={values.message} />
                    </Form.Group>

                    <div className="btns">
                        <button type="submit" className="btn alt" disabled={isSubmitting}>
                            Start a Conversation
                        </button>
                    </div>
                    <small>Please read our <Link to="/">Privacy Policy</Link> to see how we use your personal information.</small>
                </form>
            )}
        </Formik>
    )
}

export default ContactQueryForm
