import React, { useEffect } from 'react';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';
import $ from "jquery";
import { Formik } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */

import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import NewsletterForm from '@components/forms/newsletter-form-horizontal';

const NewsOver = (props) => {


  let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
      disable: 'mobile'
    });
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  if (typeof window !== "undefined") {
    $(window).scroll(function () {
      var sticky = $('.sticky-header'),
        scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
    });
  }
  return (
    <>
      <div className="breadcrumbs-intro news-form horizontal-news-form">
        <div className="static-search">
        <Intro>
           <NewsletterForm />
           </Intro>
        </div>
      </div>
    </>
  )
}

export default NewsOver
