import React, { useEffect } from 'react'
import LandingImg from "../../images/valuation-bg.png";
import { Col, Container, Row } from 'react-bootstrap';
import Helmet from "react-helmet"
import './style/_index.scss';

const ThankYou = () => {
    const time = sessionStorage.getItem('time');

    return (
        <>
            <Helmet>
                <script type="text/javascript" innerHTML='(function() {  gtag("event", "conversion", {"send_to": "AW-10960411512/9CJyCMm89NMDEPi2quoo"}); }) ();'></script>
                <script innerHTML="gtag('event', 'conversion', {'send_to': 'AW-10960411512/9CJyCMm89NMDEPi2quoo'});"></script>
            </Helmet>



            <div className='thankyou-landing'>
                <div className="thankyou-content">
                    <Row className='justify-content-md-center'>
                        <Col md="12" lg={7} className="i_pad_flex">
                            <div className='thankyou-inner'>
                                <div className="thank-you">
                                    <span>Thank you.</span>
                                    <p>All done! We look forward to seeing you on {time}.</p>
                                    <div class="btn-wrap">
                                        <a class="btn btn-success" href="/property/for-sale/in-london/">Find a Property</a>
                                        <a class="btn btn-success" href="/contact/our-offices/">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ThankYou