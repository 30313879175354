import React, { useEffect, componentDidMount } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import Video from '@components/video/video';
import $ from "jquery";
import Img from 'gatsby-image';

/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */

import TextColumns from '@components/text-columns/text-columns';
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import LandingAccordion from '@components/landing-accordion/landing-accordion';

const HomeContact = (props) => {

  const data = useStaticQuery(graphql`
    query LandingAccordionQuery {  
      site {
        siteMetadata {
          title
        }
      }
      
    allStrapiAllMenus {
      edges {
        node {
          id
          Label
          Secondary_URL
          Show_In_Burger_Menu
          URL
          Hide_Menu
          main_parent {
            URL
            Show_In_Burger_Menu
            Label
            Secondary_URL
          }
          sub_parent {
            URL
            Label
            Secondary_URL
          }
        }
      }
    }    
    }
    `);



  useEffect(() => {
    var win = $(window);
    win.scroll(function () {
      var sticky = $('.sticky-header'),
        scroll = win.scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
    });
  });
  const image_url = props.VideoBG.internal.description ? props.VideoBG.internal.description.replace("File ","").replace('"','').replace('"','') : '';  

  return (

    <>


      {props.Title != null && props.Content != null &&
        <>
                    
          <div className="landing-intro" data-aos="fade-up" data-aos-duration="1000">
            
            <TextColumns>
              <Col md="12" lg={6}>
                <div className="static-intro">
                  <Intro>
                    <Breadcrumbs />
                    <div className="intro__text">
                      <h1>{props.Title}</h1>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content}/>
                      <LandingAccordion BurgerMenu={data.allStrapiAllMenus} />
                    </div>

                  </Intro>
                </div>
              </Col>

              <Col md="12" lg={6} className="pr-0 pl-0">
                <div className="content d-none d-lg-block">
                  
                </div>
              </Col>

            </TextColumns>
          </div>
          <div className="content d-none d-lg-block landing-image-block">
            <div className="landing-sticky-img">
          <img  src={image_url}/>
          <div className="overlay-landing"></div>
          </div>
          </div>
        </>
      }
    </>
  )
}

export default HomeContact
