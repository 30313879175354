import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import classNames from 'classnames'
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { Col, Row, Container } from 'react-bootstrap';
import Img from 'gatsby-image';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import DateFormat from '@components/format-date/';
import { Link } from "gatsby";
import { Logo } from '@components/icon/icon';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import $ from 'jquery'
/**
 * Assets
 */

import './styles/_index.scss';

/**
 * Components
 */
import PositionBox from '@components/position-box/position-box';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

const GET_ALL_NEWS = gql`
query GETNEWS{
  newsEvents (sort:"Published_Date:desc") {
    id
    Title
    URL
    Content
    Featured
    Banner_Image {
      url
    }
    Published_Date
    imagetransforms
  }
}
`;

const postsPerPage = 12;
let arrayForHoldingPosts = [];
function isScrolledIntoView(elem) {
    if (typeof window !== "undefined") {

    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
}
function NewsOverviewGrid(){
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(12);
    const [] = useState(true);
    
    const { loading, data } = useQuery(GET_ALL_NEWS);
    const [testimonials, setTestimonials] = useState([])

  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };


  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, [testimonials]);
  
  useEffect(() => {
    if(data && data.newsEvents && data.newsEvents.length > 0){
        setTestimonials(data.newsEvents);
    }
    }, [data]);

  
   
    
    if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);   
     
    return (
        <div className="static-wave">
            <div className={classNames('wave news-wave', { 'is-active': inView })} ref={ref}>
                <Container>
                    {testimonials.length > 0 && testimonials.map((item, index) => {  
                         let processedImages = JSON.stringify({});
                         if (item?.imagetransforms?.Banner_Image_Transforms) {
                             processedImages = item.imagetransforms.Banner_Image_Transforms;
                         }	
                        if(item.Featured === true) {
                        return (
                    <Row className="justify-content-between">
                        <Col lg="8">
                            <div className="image">
                                <ImageTransform imagesources={item.Banner_Image.url} renderer="srcSet" imagename="news-events.Banner_Image.commontile"attr={{ alt: item.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={item.id}/>

                            </div>
                        </Col>

                        <Col lg="4">
                            <div className="img-text-box">
                            <h4><DateFormat date={item.Published_Date} /></h4>
                                <h3>{item.Title}</h3>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item.Content.replace(/<[^>]+>/g, '').substring(0,500)}/>

                                <p><Link to={`${item.URL}/`}>Continue Reading</Link></p>
                            </div>
                        </Col>
                    </Row>

                    )
                    }
                    })}

                    <div className="news-grid">
                        <Row>
                        {postsToShow.length > 0 && postsToShow.map((item, index) => { 

                        //if(index != 0) {
                        return (
                            <Col md="6" lg="4" className="news-wrap">
                                <PositionBox>
                                    <p><DateFormat date={item.Published_Date} /></p>
                                    <Link to={`${item.URL}/`}><h4>{item.Title}</h4></Link>
                                    <hr />
                                </PositionBox>
                            </Col>
                            )
                        //}
                        })}
                            
                        </Row>
                        <button className="btn btn-default btn-pagination" onClick={handleShowMorePosts}>Load more</button>
                    </div>
                    
                </Container>
            </div>
        </div>
    )
}

export default NewsOverviewGrid
