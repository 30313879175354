import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

/** 
 * Assets
 */
import './styles/_index.scss';

const ReviewLine = ({children}) => {
    const data = useStaticQuery(graphql`
        query {
        reviews: file(relativePath: { eq: "reviews-purple.png" }) {
            childImageSharp {
                fluid(maxWidth: 138, maxHeight: 62) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    return (
        <div className="reviews-line">
            <div className="reviews">
                {children}
            </div>
        </div>
    )
}

export default ReviewLine
