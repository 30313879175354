import React, { useState } from 'react';
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import FsLightbox from 'fslightbox-react';
import { Carousel } from 'react-bootstrap';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";


/**
 * Assets
 */

import './styles/_index.scss';

/**
 * Components
 */
import { Fullscreen, Heart } from '@components/icon/icon';


const PropertyGallery = (props) => {
    let processedImages = JSON.stringify({});
    if (props.data?.imagetransforms?.images_Transforms) {
        processedImages = props.data.imagetransforms.images_Transforms;
    }

    const data = useStaticQuery(graphql`
        query {
            propertyImage: file(relativePath: { eq: "property-detail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage2: file(relativePath: { eq: "property-detail2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage3: file(relativePath: { eq: "property-detail3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const images = [data.propertyImage.childImageSharp.fluid, data.propertyImage2.childImageSharp.fluid, data.propertyImage3.childImageSharp.fluid];
    const lightImages = images.map(img => img.src);
    const lightImages2 = props.propertyimages && props.propertyimages.map(img => img.url);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1 
    });

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }

    const Slider = () => (
        <Carousel indicators={false}>
            {props.propertyimages.map((item, ind) => (
                
            
                <Carousel.Item key={ind}>
                    <a href="/" onClick={(e) => openLighboxMobile(e, ind+1)}>
                        <ImageTransform imagesources={item.url} renderer="srcSet" imagename="property.images.detail"attr={{ alt: props.address+'banner-'+ind+ ' - Anthony Pepe' }}
					imagetransformresult={processedImages} id={props.id}/>


                    </a>
                    <a href="/" className="heart d-md-none d-none">
                        <Heart />
                    </a>
                    <div className="utils">
                        <div className="utils__progress d-none d-md-flex">{ind+1}/{props.propertyimages.length} Photos</div>
                        <button type="button" className="utils__fullscreen d-md-block" onClick={(e) => openLighboxMobile(e, ind+1)}>Fullscreen<Fullscreen /></button>
                        
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    )

    return (
        <div className="property-gallery">
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                type='image'
                slide={lightboxController.slide}
            />
            <Slider />
        </div>
    )
}

export default PropertyGallery;
