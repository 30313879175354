/**
 * Number
 */
export const numberFormat = ( num ) => {
  if (!num) return 0
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  return new Intl.NumberFormat("en-US", {}).format(num)
}

/**
 * parseInt
 */
export const filterNumber = ( str ) => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

export const jsUcfirst = ( str ) => {
    return (str.charAt(0).toUpperCase() + str.slice(1));
}

export const objVals = ( obj ) => {
    return Object.values(obj);
}
