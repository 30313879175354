import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import { useMatch } from "@reach/router"

/**
 * Assets
 */
import './styles/_index.scss';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

const StaticPageBanner = (props) => {

    const match = useMatch('/about/our-people/:item');

    return match ? (
        <empty></empty>
          ) : (
        <div className={`${props.layout == 'Static_Page'?' static-landing show-breadcrumbs':''}`}>
        <div className={`contact-query static-intro breadcrumbs-intro`}>
            <Container>
            <Row className="justify-content-between">
                <Col lg="12" className="left-cnt-sec">
                    <Breadcrumbs/>              
                    <h1>{props.Title}</h1>    
                    <div className="static-content-wrap">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content}/>
                    </div>            
                    <div className="stickyremove"></div>
                </Col>
               
                    </Row>

            </Container>
        </div>
        </div>
    )
}

export default StaticPageBanner
