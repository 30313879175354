import React from "react"
import { useMatch } from "@reach/router"
import AllTeams from '@components/team-listing/all-teams';
import TeamDetails from '../../templates/team-details';

const App = () => {
  const match = useMatch('/about/our-people/:item');
  return match ? (      
    <TeamDetails DetailURL={match.item}/>
  ) : (
    <AllTeams/>
  )
}
export default App



