import React from 'react';
import Img from 'gatsby-image';

/**
 * Assets
 */

 import './styles/_index.scss';

const PositionBox = ({image, children, alt}) => {
    return (
        <div className="position-box">
            <div className="position-box__image">
                <Img fluid={image} alt={alt} />
            </div>
            {children}
        </div>
    )
}

export default PositionBox
