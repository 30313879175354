import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import { Link } from "gatsby";

/**
 * Components
 */
import PositionBox from '@components/position-box/position-box';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

/**
 * Assets
 */

import './styles/_index.scss';

const StaticPositions = (props) => {
    const data = useStaticQuery(graphql`
        query {
        image: file(relativePath: { eq: "grey-img.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
  
    return (
        
        <div className="static-positions office-landing-wrap" id="section-list-offices">
        <div className="property-fixed-bar footer-cta d-lg-none">
        <Container>
            <Row className="justify-content-between">
                <Col md="12" lg="auto">
                    <div className="fixed-utils">
                        <Link href="/value-my-property/property-valuation/" className="btn">Valuation</Link>
                        <Link to="/contact/general-enquiry/" className="btn dark d-lg-none">Enquiry</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
            <Container>
            <Col lg={8}>
            <Row>
                <h2>We are your North London estate agent.</h2>
                </Row>
            </Col>
                <div className="position-slider">
                    <Row>
                    {props.data.edges.map(({node}, key) => {
                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Tile_Image_Transforms) {
                            processedImages = node.imagetransforms.Tile_Image_Transforms;
                        }	
                        const image_url = node.Tile_Image?.internal?.description ? node.Tile_Image?.internal?.description?.replace("File ","").replace('"','').replace('"','') : '';  
                        var incekey =  key+1;
                        var delaying = incekey+'00';                        
                        return <>
                        <Col className="office-wrap-tile" data-aos-offset="50" data-aos="fade-up" data-aos-delay={delaying} md="6" lg="4">
                            <div className="position-box">
                                <Link to={`${node.URL}/`}>
                                <div className="position-box__image">
                                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Tile_Image.commontile"attr={{ alt: node.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={node.strapiId}/>
                                </div>
                                </Link>
                                <h4 className="ofc-tile"><Link to={`${node.URL}/`}>{node.Title}</Link></h4>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={node.Address}/>
                                <a href={`tel:${node.Phone_Number}`}>{node.Phone_Number}</a>
                            </div>
                        </Col>
                        </>
                    })}
                    </Row>
                </div>
            </Container>
        </div>
        
    )
}

export default StaticPositions
