/**
 * Rental Yield Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat, rentalYield } from "./util"
import { defaultValues } from "./config"
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from "gatsby";
// use project specific import here

// DO NOT DO ANY CHNAGES - START
const RentalyieldCalc = props => {
  
  const data = useStaticQuery(graphql`
        query {
        img3: file(relativePath: { eq: "calc-sidebar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
  `);

  const {
    propertyPrice,
    monthlyRent,
    currency,
    instantResult,
    pricePrefix,
  } = props

  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [rent, setRent] = useState(prefix + numberFormat(monthlyRent))
  const [result, setResult] = useState("")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(prefix + numberFormat(val))
  }

  const handleRent = event => {
    let d = filterNumber(event.target.value)
    setRent(prefix + numberFormat(d))
  }

  const getResult = (purchasePrice, rent) => {
    let result = rentalYield(filterNumber(purchasePrice), filterNumber(rent))
    setResult(numberFormat(result))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {
      if (filterNumber(purchasePrice) && filterNumber(rent) ) {
        getResult(purchasePrice, rent)
      }
    }
  }

  useEffect(() => {
    if (instantResult) {
      getResult(purchasePrice, rent)
    }
  }, [instantResult, purchasePrice, rent])

  // DO NOT DO ANY CHNAGES - END

  return (
    <div className='static-text-columns yield-calc calcpage'>
      <Row>
    <Col lg={6}>
      <div className="left-img-calc">
        <Img fluid={data.img3.childImageSharp.fluid} alt="Estats Reviews - Anthony Pepe" />
      </div>
    </Col>
    <Col lg={6} className="form-calc-right">
    <div className="form mortgate-form stamp-duty-form">
    <div className="text-columns">
    <div className='container'>
      {showerror && (
        <div className="alert-error">
          <p>Highlighted fields are required</p>
        </div>
      )}
      <Form
        name="RentalyieldCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <Form.Group controlId="purchase_price">
          <Form.Label>Property Value</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="purchase_price"
            value={purchasePrice}
            placeholder="Purchase Price"
            onChange={handlePrice}
          />
        </Form.Group>
        <Form.Group controlId="rent">
          <Form.Label>Monthly Rent</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="monthly_rent"
            value={rent}
            placeholder="Monthly Rent"
            onChange={handleRent}
          />
        </Form.Group>
        <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
      </Form>
      {result && (
        <div className="alert alert-success" role="alert">
          <p>
            Estimated rental yield: {result}%
          </p>
        </div>
      )}
    </div>
    </div>

    </div>
    </Col>
    </Row>
    </div>
  )
}

RentalyieldCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  monthlyRent: defaultValues.MONTHLY_RENT,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  instantResult: defaultValues.INSTANT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
}

RentalyieldCalc.propTypes = {
  propertyPrice: PropTypes.any,
  monthlyRent: PropTypes.any,
  currency: PropTypes.string,
  instantResult: PropTypes.any,
  pricePrefix: PropTypes.any,
}

export default RentalyieldCalc