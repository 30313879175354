import React from 'react';
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import { useMatch } from "@reach/router"
import ScrollAnimation from 'react-animate-on-scroll';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import ContactQueryForm from '@blocks/forms/contact-query';
import GeneralEnquiryForm from '@components/forms/general-enquiry';
import TextColumns from '@components/text-columns/text-columns';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ContactBox from '@components/contact-box/contact-box';
import OfficeBox from '@components/office-box/office-box';

const ContactQuery = (props) => {
    const data = useStaticQuery(graphql`
        query {
        avatar1: file(relativePath: { eq: "avatar1.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        officeImage: file(relativePath: { eq: "office-image.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 768) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    const match = useMatch('/about/our-people/:item');

    return match ? (
        <empty></empty>
          ) : (
        <div className="contact-query inner-intro breadcrumbs-intro">
            <Container>
                <Breadcrumbs/>  
                <ScrollAnimation  animateIn='animate__fadeInUp' animateOnce={true} >                                      
                <h1>{props.Title}</h1>   
                </ScrollAnimation>
                <ScrollAnimation  animateIn='animate__fadeInUp' animateOnce={true} >                                      
             
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content}/>
                </ScrollAnimation>

            </Container>
        </div>
    )
}

export default ContactQuery
