import React, {  } from 'react';
import { Col } from 'react-bootstrap';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ContactBox from '@components/contact-box/contact-box';


const SINGLE_TEAM = gql`
query GetTeamDetails ($EmailID: String!){
    teams (where:{Email_contains:$EmailID}) {
        id
        URL
        Name
        Email
        Phone
        Designation
        Image {
          url
        }
  }
}
`;



function TeamDetails(props) {
  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { EmailID: (props.negotiator == undefined ?  props.negotiator_details.email :  props.negotiator.email)}
  });
  if (loading) return <p>Loading ...</p>;  
  return (
    <>
      {data && data.teams.map((team, key) => {
        if(key == 0) {
        return <>
			<Col md="6" lg="12">
				<ContactBox name={props.negotiator == undefined ? props.negotiator_details.name : props.negotiator.name} phone={team.Phone} email={team.Email} link={team.Calendly_Link} linkText="Video Call
" teamimage={`${team.Image && team.Image.url}`} />

			</Col>
        
        </>
        }
      })}
    </>
  )
}

export default TeamDetails
