import React from 'react';
import Img from 'gatsby-image';

/**
 * Assets
 */
import './styles/_index.scss';
  

const OfficeBox = ({image, children, title, id}) => {

const image_url = image.internal.description ? image.internal.description.replace("File ","").replace('"','').replace('"','') : '';
    return (
        <div className="office-box">
            <div className="office-box__image">
            <img src={image_url} alt={`${title} - Anthony Pepe`}/>
            </div>
            <div className="office-box__content">
                {children}
            </div>
        </div>
    )
}

export default OfficeBox;
