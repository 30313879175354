import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';
import { Container, Modal } from 'react-bootstrap';

import $ from "jquery";
import PlayIcon from '@components/play-icon/play-icon';
import BackgroundImage from 'gatsby-background-image'
import loadable from '@loadable/component'
//import VideoPlay from "../../components/play/video-play";
import getVideoId from 'get-video-id';
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql} from 'gatsby';
import Estas from "./images/estas.svg";

/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */

import Intro from '@components/intro/intro';

const InnerBannerContent = (props) => {

  if (typeof window !== "undefined") {

    $.getJSON('https://www.theestas.com/api/widget?api_token=qayro8ImsRiW2xXhxJoabXNVgLYnOG5flqiJ34WhdLQ7WtXOg1rs1Cos6B8E&with_comments=true&demo=0', function(data) {
        var text = data.reviewCount;    
        var rating = data.rating+'%';    
        $(".mypanel").html(text);
        $(".myrating").html(rating);
    })
	}
    const data = useStaticQuery(graphql`
        query {
            reviews: file(relativePath: { eq: "reviews.png" }) {
            childImageSharp {
                fluid(maxWidth: 100, maxHeight: 44, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

  const VideoPlay  = loadable(() => import('../play/video-play'));
  const VideoURLid = props.VideoURL && getVideoId(props.VideoURL);
  
  const [isPlay,setPlay] = useState(false);
  const [isPlaying,setPlaying] = useState(false);

  const [show, setShow] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const [controls, setVideoControls] = useState(false);
  const [mute, setMute] = useState(true);
  const vidRef = useRef(null);
  const pausevideo = (ref) => {
      setPlayvideo(false)
      setVideoControls(false)

  }
  const playvideo = (ref) => {
      setPlayvideo(true)
      setVideoControls(false)
  }
  const showControls = () => {
      setVideoControls(true)
  }
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const trackerVideo = (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': url,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
      });
  }
  let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
      disable: 'mobile'
    });
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  if (typeof window !== "undefined") {
  $(window).scroll(function () {
      var sticky = $('.sticky-header'),
          scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
  });
  }

  return (
    <>      
        <BackgroundImage
          Tag={`div`}
          className="inner-banner inner-banner-content chamberlains"
          fluid={props.BannerImage}
          style={{
            backgroundPosition: '0 0 !important',
          }}
        >
        <div className="bg-overlay"></div>
        <div className="bg-wrapper">
        <Intro>
          <div className="intro__text" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" >
            <h1>{props.Title}</h1>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content}/>            
            <div className="inner-banner-actions">
            {props.VideoURL !=null && props.VideoURL !='' &&
              <strong className="play-btn" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                <PlayIcon />
              </strong>
            }
            <p>{props.Caption}</p>
      

            {(props.Ctas.length>0) && (             
                  <div className="btn-list">
                    {props.Ctas.map((cta, i) => {
                      var  parenturl = cta?.ctlink?.Secondary_URL
                      var pageurl = parenturl?parenturl:cta?.ctlink?.URL
                                return (
                                  <Link to={`${pageurl==="general-enquiry"?"/contact/general-enquiry":pageurl}`} className={`btn`}>{cta.Label}</Link>
                                )
                    } ) }
                  </div>
                )}
                <div className="intro__reviews_container">
                    <div className="intro__reviews">
                    <img src={Estas} alt="Estats Logo - Anthony Pepe" />
                    <p><span className='myrating'></span> <span className="d-none d-md-inline">Customer Service Rating</span> from <strong><span className='mypanel'></span> Reviews</strong></p>
                    </div>
                </div>
            </div>
          </div>
        </Intro>
        </div>
      </BackgroundImage>      
    </>
  )
}

export default InnerBannerContent
