import React, {  } from 'react';
import Video from '@components/video/video';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "gatsby";
import useDeviceDetect from '@blocks/detect-device/detect-device';

const SINGLE_AREA = gql`
query GetTeamDetails ($Prop_Postcode: String!){
    areaGuides (where:{Areas_Coverd_contains:$Prop_Postcode}) {
      URL   
      Title
      Embed_Video_URL   
      Tile_Image {
        url
      }
    }
  
}
`;



function TeamDetails(props) {
  const { isMobile } = useDeviceDetect();

  const { loading, error, data } = useQuery(SINGLE_AREA, {
    variables: { Prop_Postcode: props.area }
  });
  if (loading) return <p>Loading ...</p>;
  return (
    <>
      {data && data.areaGuides && data.areaGuides.map((node, key) => {
        if(key ===0) {
        return <>
        <Video src={node.Embed_Video_URL} height={isMobile ? '250px' : '400px'} image={node.Tile_Image.url} />
        Located in {props.address1}, {props.address2} 
        <span><Link to={`/about/area-guide/${node && node.URL}/`}>Read {node.Title} Area Guide</Link></span>
        </>
        }
      })}
    </>
  )
}

export default TeamDetails
