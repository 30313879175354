import React, { useContext, useEffect } from 'react';
import classNames from 'classnames'
import { Link, useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import $ from "jquery";

/**
* Assets
*/

import './styles/_index.scss';

/**
* Components
*/

import { LogoElement } from '@components/icon/icon';
import ContactBox from '@components/contact-box/contact-box';

function ContextAwareToggle({ children, eventKey, callback }) {
const currentEventKey = useContext(AccordionContext);
const decoratedOnClick = useAccordionToggle(
eventKey,
() => callback && callback(eventKey),
);

const isCurrentEventKey = currentEventKey === eventKey;

return (
<button
type="button"
className={classNames('toggle-button', { 'active': isCurrentEventKey })}
onClick={decoratedOnClick}
>toggle-button</button>
);
}

const ModalNav = (props) => {
if (typeof window !== "undefined") {
var pageURL = window.location.href ? window.location.href : '';
var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
}

useEffect(() => {
return function () {
console.log(1)
document.querySelectorAll('body,html').forEach(item => item.classList.remove('overflow'))
}
}, [])

return (
<Accordion>
<ul className="parent-menu toogle-parent-menu">

{props.BurgerMenu.edges.map(({ node }, key) => {
 
return <>
{
    node.Hide_Menu != true && node.main_parent != null && node.sub_parent == null && node.main_parent.URL === lastURLSegment && (
        <>
            <li data-aos="fade-right" data-aos-offset="0" data-aos-delay="100">
                {!node.Hide_Menu &&
                    <Link to={`/${node.main_parent.URL}/${node.URL}/`}>{node.Label}</Link>
                }
                <ul>
                    {props.BurgerMenu.edges.map((submenu, index) => {
                        return <>
                            {submenu.node.sub_parent != null && submenu.node.main_parent != null && submenu.node.sub_parent.Label === node.Label && 
                                <ContextAwareToggle className="collapse-list" eventKey={node.id} />
                            }

                        </>
                    })}
                </ul>

                <Accordion.Collapse eventKey={node.id}>
                    <Nav className="dropdown-list">
                        <ul>
                            {props.BurgerMenu.edges.map((submenu, key2) => {
                                return <>
                                    {submenu.node.sub_parent != null && submenu.node.main_parent != null && submenu.node.sub_parent.Label === node.Label &&
                                        <li data-aos="fade-right">
                                            {submenu.node.Secondary_URL ?
                                            <Link to={submenu.node.Secondary_URL}>{submenu.node.Label}</Link>
                                            :
                                            <Link to={`/${node.main_parent.URL}/${node.URL}/${submenu.node.URL}/`}>{submenu.node.Label}</Link>
                                            }
                                        </li>
                                    }
                                </>
                            })}
                        </ul>
                    </Nav>
                </Accordion.Collapse>

            </li>

        </>

    )
}

</>

})}
</ul>


</Accordion>
)
}

export default ModalNav
