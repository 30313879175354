import React from 'react';
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useMatch } from "@reach/router"
import $ from 'jquery'
/**
 * Components
 */
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';


const HomeIntro = (props) => {
	if (typeof window !== "undefined") {

    $.getJSON('https://www.theestas.com/api/widget?api_token=qayro8ImsRiW2xXhxJoabXNVgLYnOG5flqiJ34WhdLQ7WtXOg1rs1Cos6B8E&with_comments=true&demo=0', function(data) {
        var text = data.reviewCount;    
        var rating = data.rating+'%';    
        $(".mypanel").html(text);
        $(".myrating").html(rating);
    })
	}
      function gotohash(event) {
        var thishash = '#'+event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top-250
        }, 2000);
    }
    const match = useMatch('/');
    const data = useStaticQuery(graphql`
        query {
            reviews: file(relativePath: { eq: "reviews.png" }) {
            childImageSharp {
                fluid(maxWidth: 100, maxHeight: 44) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        allStrapiAllMenus {
          edges {
            node {
              id
              Label
              Show_In_Burger_Menu
              URL
              main_parent {
                URL
                Show_In_Burger_Menu
                Label
              }
              sub_parent {
                URL
                Label
              }
            }
          }
        }  
        }
    `);

    return (

        <Intro>
            {match ==null &&
                <Breadcrumbs/>                    
            }
            <div className="intro__text">
                <h1>{props.Title}</h1>    
            </div>
            <div className="intro__btns">
            {props.CTA1Link &&
                <Link className="btn" to={`${props.CTA1Link}/`}>{props.CTA1Label}</Link>
            }
            {props.CTA2Link &&
                <Link className="btn" to={`${props.CTA2Link}/`}>{props.CTA2Label}</Link>
            }
            </div>
            {props.review == true &&
            <div className="intro__reviews">
                <Img fluid={data.reviews.childImageSharp.fluid} alt="Estats Logo - Anthony Pepe" />
                <p><span className='myrating'></span> <span className="d-none d-md-inline">Customer Service Rating</span> from <strong><span className='mypanel'></span> Reviews</strong></p>
            </div>
            }
        </Intro>
    )
}

export default HomeIntro
