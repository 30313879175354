import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss';

const Stats = ({children}) => {
    return (
        <div className="stats">
            <Container>
                {children}
            </Container>
        </div>
    )
}

export default Stats
