import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { node } from "prop-types";
import $ from "jquery";
import { Logo } from '@components/icon/icon';
import ImgBox from '@components/img-box/img-box';
import { Container } from 'react-bootstrap';

const GET_TEAM_MENUS = gql`

query TeamMenus {
    teamCategories {
        Name
        URL
    }
}
`;


function TeamMenus(props,handleClick,data) {

 function filterTeam(event,data) {
    var filtername = event.target.getAttribute('data-filter')
    $('.category-list-team .active').removeClass("active");
    $('.team-wrap').hide();
    $('.'+filtername).show();
    $(event.target).addClass("active");    
    if(filtername == 'all') {
        $('.team-wrap').show();
    }
    $('html, body').animate({
        scrollTop: $(".team-listing-intro").offset().top-100
    }, 2000);

}
    useEffect(() => {
     

    }, []); 

const { loading:newsmenuloading, error:newsmenuerror, data:newsmenu } = useQuery(GET_TEAM_MENUS);

  if (newsmenuloading) return <p>Loading ...</p>

  return (
  	<>
   
        <ul className="category-list-team">
			<li>
                <a onClick={filterTeam} data-filter="all" className={props.active + " active"} >All People</a>
			</li>
			{newsmenu.teamCategories.map((node, index) => {
			return (
			<li>
			<a  onClick={filterTeam}  data-filter={node.URL}>{node.Name}</a>
			</li>
			)
			})}
							
		</ul>
		</>
    );
}


export default TeamMenus