import React from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'react-bootstrap';
import { Link } from 'gatsby';

/**
 * Assets
 */

import './styles/_index.scss';

const ChatForm = () => {
    return (
        <Formik
            initialValues={{ name: '', email: '', phone: '', picked: "I’m looking to sell or let my property", message: '' }}
            validate={values => {
                const errors = {};
                if (!values.name) {
                    errors.name = 'Required';
                }
                if (!values.email) {
                    errors.email = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className="chat-form">
                    <Form.Group>
                        <Field name="name" type="text" placeholder="Your Name" value={values.name} />
                    </Form.Group>
                    <Form.Group>
                        <Field name="email" type="email" placeholder="Your Email Address" value={values.email} />
                    </Form.Group>

                    <div className="btns">
                        <button type="submit" className="btn" disabled={isSubmitting}>
                            Let’s Chat
                        </button>
                    </div>
                    <small>By clicking Let’s Chat, you agree to our <Link to="/">Terms &amp; Conditions.</Link></small>
                </form>
            )}
        </Formik>
    )
}

export default ChatForm
