import React, { useEffect } from 'react';

import { Col, Row, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";

/**
 * Assest
 */
import './styles/_index.scss';

import TextColumns from '@components/text-columns/text-columns';
import BookaViewingForm from '@components/forms/book-a-view-form';
import GetOfficeTeam from './get-office-team';
import GetPropertyTeam from './get-property-team';

const PropertyContact = (props) => {
    const data = useStaticQuery(graphql`
        query {
        avatar1: file(relativePath: { eq: "avatar1.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        avatar2: file(relativePath: { eq: "avatar2.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    let AOS;
    useEffect(() => {
      const AOS = require("aos");
      AOS.init({
        once: true,
        disable: 'mobile'
      });
    }, [])


    return (
        <div className="property-text-columns property-sale">
               
        <div id = "book-viewing" className="property-contact" data-aos="fade-up">
            <TextColumns>
                <Col lg="5">
                    <h3>Are you interested in this property? Fill in the form to know more by booking a viewing request or get in touch with one of our office contacts.</h3>
                    <Row>
                        {props.negotiator ?
                        <GetPropertyTeam negotiator={props.negotiator}/> :
                        <GetOfficeTeam officecrmid={props.officecrmid}/>
                        }
                    </Row>
                </Col>
                <Col lg="7">
                    <div className="content">
                        <BookaViewingForm propertyurl={props.propertyurl} search_type={props.search_type} />
                    </div>
                </Col>
            </TextColumns>
        </div>
        
        </div>
    )
}

export default PropertyContact
