import React, {useState,useEffect} from 'react';
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useMatch } from "@reach/router"
import $ from 'jquery'
/**
 * Components
 */
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import SearchOverlayLink from '@components/modal-nav/search-form-link';


const HomeIntro = (props) => {
    const [Reviews, setReviews] = React.useState(false);
	if (typeof window !== "undefined" && Reviews == false) {
    $.getJSON('https://www.theestas.com/api/widget?api_token=qayro8ImsRiW2xXhxJoabXNVgLYnOG5flqiJ34WhdLQ7WtXOg1rs1Cos6B8E&with_comments=true&demo=0', function(data) {
        var text = data.reviewCount;    
        var rating = data.rating+'%';    
        $(".mypanel").html(text);
        $(".myrating").html(rating);
        setReviews(true)
    })
	}

    function gotohash(event) {
        var thishash = '#'+event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top-250
        }, 2000);
    }
    
    const match = useMatch('/');
    
    if (typeof window !== "undefined") {
        if (match && $(window).width() < 767) {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll >= 40) {
                    //console.log('matchmatch', match);
                    if($('.homestickycta').length > 0 ) {
                        $('.footer-cta').show();
                    }
                    if($('.homestickycta').length === 0 ) {
                        $('.intro .intro__btns').hide();
                        var HomeCTA= ''
                        $('<div class="homestickycta property-fixed-bar footer-cta d-lg-none"><div class="container"><div class="justify-content-between row"><div class="col-lg-auto col-md-12"><div class="fixed-utils"><a href="/property/for-sale/in-london/" class="btn dark d-lg-none">Find a Property</a><a href="/value-my-property/property-valuation/" class="btn">Value my property</a></div></div></div></div></div>').insertAfter(".inner-page");
                    }
                }   else {
                    $('.footer-cta').hide();
                    $('.intro .intro__btns').show();
                }
            });            
        }
    }
    const data = useStaticQuery(graphql`
        query {
            reviews: file(relativePath: { eq: "reviews.png" }) {
            childImageSharp {
                fluid(maxWidth: 100, maxHeight: 44, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        allStrapiAllMenus {
          edges {
            node {
              id
              Label
              Show_In_Burger_Menu
              URL
              Secondary_URL
              main_parent {
                URL
                Show_In_Burger_Menu
                Label
              }
              sub_parent {
                URL
                Label
              }
            }
          }
        }  
        }
    `);

    return (

        <Intro>
            {match ==null &&
                <Breadcrumbs/>                    
            }
            <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" className="intro__text">
                <h1>{props.Title}</h1>    
            </div>
            <div data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-sine" className="intro__btns">
            {props.CTA1Hash !=null && props.CTA2Hash ==null &&
                <>
                <a onClick={() => gotohash(props.CTA1Hash)} className="btn hashcta" data-hash={`#${props.CTA1Hash}`} href="#">{props.CTA1Label}</a>
                </>
                }
            {data.allStrapiAllMenus.edges.map(({node}, key) => {
            if(props.CTA1Label === "Find a Property" && node.URL == props.CTA1Link) {
                return<>
                <SearchOverlayLink class="btn" label= "Find a Property" />                    
                </>
            } else {
            return <>
                {node.main_parent != null && node.URL == props.CTA1Link && node.sub_parent == null && 
                <Link className="btn" to={`/${node.main_parent.URL}/${props.CTA1Link}/`}>{props.CTA1Label}</Link>
                }
                {node.main_parent != null && node.sub_parent != null && node.URL == props.CTA1Link &&
                <Link className="btn" to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${props.CTA1Link}/`}>{props.CTA1Label}</Link>
                }

                {node.main_parent != null && node.sub_parent != null && node.URL == props.CTA2Link &&
                <Link className={`btn ${props.btnclass}`} to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${props.CTA2Link}/`}>{props.CTA2Label}</Link>
                }

                {node.main_parent != null && node.URL == props.CTA2Link && node.sub_parent == null &&
                <Link className={`btn ${props.btnclass}`} to={`/${node.main_parent.URL}/${props.CTA2Link}/`}>{props.CTA2Label}</Link>
                }   
                {props.CTA1Link !=null && props.CTAParent1 == null && node.URL == props.CTA1Link &&
                <>
                <Link className="btn" to={`${node.Secondary_URL?node.Secondary_URL:'/'+props.CTA1Link}/`}>{props.CTA1Label}</Link>
                </>
                }                            
            </>
            }
            })}
            

            {props.CTA2Link !=null && props.CTAParent2 == null &&
                <>
                <Link className={`btn ${props.btnclass}`} to={`/${props.CTA2Link}/`}>{props.CTA2Label}</Link>
                </>
            }
                {props.CTA1Hash !=null && props.CTA2Hash !=null && 
                <>
                <a onClick={() => gotohash(props.CTA1Hash)} className="btn hashcta" data-hash={`#${props.CTA1Hash}`} href="#">{props.CTA1Label}</a>
                <a onClick={() => gotohash(props.CTA2Hash)} className={`btn hashcta ${props.btnclass}`} href="#">{props.CTA2Label}</a>
                </>
                }
 
                {props.CTA2Hash !=null && props.CTA1Hash ==null &&
                <>
                <a onClick={() => gotohash(props.CTA2Hash)} className={`btn hashcta ${props.btnclass}`} href="#">{props.CTA2Label}</a>
                </>
                }
           

            </div>
            {props.review == true &&
            <div data-aos-offset="0" data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine" className="intro__reviews">
                <Img fluid={data.reviews.childImageSharp.fluid} alt="Estats Logo - Anthony Pepe"/>
                <p><span className='myrating'></span> <span className="d-none d-md-inline">Customer Service Rating</span> from <strong><span className='mypanel'></span> Reviews</strong></p>
            </div>
            }
        </Intro>
    )
}

export default HomeIntro
