import React from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const [isTablet, setTablet] = React.useState(false);

  React.useEffect(() => {
    const mobile = [
      'iPhone Simulator',
      'iPod Simulator',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    const tablet = [
      'iPad Simulator',
      'iPad',
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    setMobile(mobile);
    setTablet(tablet);
  }, []);

  return { isMobile, isTablet };
}