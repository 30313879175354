import React, { useState, useRef,useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import PersonVideo from '@components/person-video/person-video';
import TeamMenus from '@components/team-listing/team-category';
import { Link, Router } from "@reach/router"
import Modal from 'react-bootstrap/Modal'
import MemberForm from "../forms/member-form"
import { Logo } from '@components/icon/icon';
import ImgBox from '@components/img-box/img-box';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import PlayIcon from '@components/play-icon/play-icon';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';

import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

const GET_ALL_TEAMS = gql`
query GETTEAMS{
  teams  (sort:"Sort:asc") {
    imagetransforms
    id
    Designation
    Name
    URL
    Phone
    Email
    About
    Embed_Video_URL
    Calendly_Link
    Image {
        url
    }
    Select_Categories {
        Name
        URL
    }
  }
}
`;



function TeamList() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [teamid, setteamid] = useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const [videoindex, setVideoindex] = React.useState('default');
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const vidRef = useRef(null);
  let AOS;
  useEffect(() => {
      const AOS = require("aos");
      AOS.init({
          once: true
      });
  }, [])

  useEffect(() => {
      if (AOS) {
          AOS.refresh();
      }
  });
  const [isPlay,setPlay] = React.useState(false);
    const playVideo = (video_index, video_id) => {
      setVideoindex(video_index)   
      setShowVideo(true)
    }
    const trackerVideo = (event) => {    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': url,
        'formId': 'Video Play',
        'formName': 'Video',
        'formLabel': 'Video'
      });
    }

  const handleShow = (event) => {
    setteamid(event);
    setShow(true);
}
    const { loading, error, data } = useQuery(GET_ALL_TEAMS);
   
    if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);
    var cat_array = []
    return (
        <>
        <div className="static-person-video team-section team-listing-intro">
            <Container>
                <div class="team-head">
                    <TeamMenus />
                </div>
                <div className="slider-holder row">
                    {data.teams.map((node, index) => {
                         cat_array = []
                         if(node.Embed_Video_URL !=null && node.Embed_Video_URL != '') {
                          var videoid = getVideoId(node.Embed_Video_URL);
                      }  
                        {node.Select_Categories.map((catname) => {
                            cat_array.push(catname.URL)
                        })}
                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Image_Transforms) {
                            processedImages = node.imagetransforms.Image_Transforms;
                        }
                        var incekey =  index+1;
                        var delaying = incekey+'00';   
                        return <>
                        <div className = {`team-wrap col-lg-4 col-md-6 ${cat_array.join(' ')}`}>
                        <ScrollAnimation offset={!isMobile?1000:''} delay={delaying} animateIn="animate__fadeInUp" animateOnce={true}>                                  
                            <div className="person-video">
                            <div className="vide-wrapper video-box">
                            <ImageTransform imagesources={node.Image.url} renderer="srcSet" imagename="team.Image.commontile"attr={{ alt: node.Name+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={node.id}/>
                            {showVideo && videoindex== index &&
                            <>
                            <div className="videoBTNClose">
                                <a onClick={e => {setPlay(false);setShowVideo(false);setVideoindex('default');}}></a>
                            </div>
                                    
                                    <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(node.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false);setVideoindex('default'); }} width='100%' height='100%' />
                                    </>
                            }
                            </div>

                              <h4>{node.Name}</h4>
                              <h6>{node.Designation}</h6>

                                <h6>{node.Select_Categories.URL}</h6>
                                <p><a href={`tel:${node.Phone}`} className="phone">{node.Phone}</a>  /  
                                 <a href="javascript:void(0);" onClick={ () => handleShow(node.id)}> Email</a>
                                  {node.Calendly_Link &&
                                  <>
                                  /  <a href={node.Calendly_Link}>Video Call</a>
                                  </>
                                  }
                                  </p>
        {teamid && teamid == node.id &&
      <>
        <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form">

          <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100">CONTACT - {node.Name}
            </Modal.Title>
              </Modal.Header>
        <Modal.Body>
        <MemberForm to_email_id={node.Email} staffname={node.Name} formName={`Contact Person`} />
        </Modal.Body>
      </Modal>
      </>
        }
    
    {node.Embed_Video_URL !=null && node.Embed_Video_URL != '' && videoindex != index &&
                                <a href="javascript:;" onClick = { (e) => {playVideo(index, videoid.id)}}>
                                <PlayIcon />
                                </a>
                            }

    </div>
    </ScrollAnimation>

                        </div>
                        </>
                    })}
                        
                </div>
            </Container>
        </div>
        </>
    )

                }
export default TeamList
