import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import $ from 'jquery';
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import TextColumns from '@components/text-columns/text-columns';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'


const PropertyTextColumns = (props) => {
    const regex = /.*?(\.)(?=\s[A-Z])/;
    var propdesc = props.description.replaceAll("&#151;", "-");
    var descresult = propdesc.replaceAll('&#146;','').replaceAll('&#147;','').replaceAll('&#148;','').replaceAll('&#149;','').replaceAll('&#150;','');

useEffect(() => {
    setTimeout(function () {
        //var leftcnt = $(".propertycontent").find('p:first').text()
        //$('.leftprop h3').html(propdesc.html().split("."))
        //var textParts = leftcnt.html().split(".");
        //$(".propertycontent p").first().addClass('first-para')
        //$(".propertycontent .first-para").show()
        var elems = $('.propertycontent p');
        var leftelems = $('.leftprop h3');
        var IntroText = "";
        //console.log('leftelems',leftelems.length)
        if(elems.length == 1) {
            //if(leftelems.length <= 0) {
                elems.each( function(){
                    var elem = $(this);
                    var textParts = elem.html().split(".");
                    if(typeof(textParts[0])!=="undefined" && textParts[0]) {
                       IntroText = textParts[0].replace(/<span.*?>.*?<\/span>/g, '') +'.';                      
                    }
                    var first = "<span class='firstline'>" + textParts.shift() + ".</span>";
                    elem.html(first + textParts.join("."));
                });
                var leftcnt = $(".propertycontent").find('.firstline').text()
                if(IntroText) {
                     $('.leftprop h3').html(IntroText)
                }else{
                     $('.leftprop h3').html(leftcnt)
                }
            //}
        $('.propertycontent .firstline').hide();
    } else {
        var leftcnt = $(".propertycontent").find('p:first').text()
        $('.leftprop h3').html(leftcnt)
        $(".propertycontent p").first().addClass('first-para')
        $(".propertycontent .first-para").hide()
    }
    }, 1000);
}, [])

    return (
        <div className="property-text-columns property-sale" id="details">
            <TextColumns>
                <Col md={12} lg={5} className="leftprop">
                    <h3></h3>
                    <ul>
                        {
                            props.acc_summ && props.acc_summ.split(',').map((item, index) => {
                                if (item) {
                                    return (
                                        <li>{item}</li>
                                    )
                                }

                            })
                        }
                    </ul>


                </Col>
                <Col md={12} lg={7}>
                    <div className="content propertycontent">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={descresult}/>
                    </div>
                </Col>
            </TextColumns>
        </div>
    )

}

export default PropertyTextColumns
