import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { Col } from 'react-bootstrap';
import Img from 'gatsby-image';

/**
 * Components
 */
import RowImgText from '@components/row-img-text/row-img-text';
import ReviewLine from '@components/review-line/module-line';

const HomeRowImgText = () => {
    const data = useStaticQuery(graphql`
        query {
        img3: file(relativePath: { eq: "prime-logo-new.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reviews: file(relativePath: { eq: "reviews-purple.png" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    return (
        <div className="prime-module">
        <RowImgText>
            <Col md="12" lg="5" className="image-holder">
                <div className="image">
                    <Img fluid={data.img3.childImageSharp.fluid} alt="Introducing Anthony Pepe Prime - Anthony Pepe"/>
                </div>
            </Col>
            <Col className="img-content-module" md="12" lg={{
                offset: 1,
                span: 6
            }}>
                <ReviewLine>
                    <h3>Introducing Anthony Pepe Prime </h3>
                </ReviewLine>
                <p>Accelerate your property sale / let with our Prime service.</p>
                <Link to="/property-services/sell/anthony-pepe-prime-services/" class="btn alt btn">Find Out More</Link>
            </Col>
        </RowImgText>
        </div>
    );
}

export default HomeRowImgText