import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row, Carousel } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import PropertyBox from '@components/property-box/property-box';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

const PropertiesBoxes = (props) => {
    const data = useStaticQuery(graphql`
        query {
        pr1: file(relativePath: { eq: "pr1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        pr2: file(relativePath: { eq: "pr2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    let AOS;
    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
            disable: 'mobile'
        });
    }, [])

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });

    const Slider = () => (
        <Carousel indicators={true}>
            {props.data && props.data.length > 0 ? props.data.map((item, key) => {

                        let processedImages = JSON.stringify({});
                        if (props.data?.imagetransforms?.images_Transforms) {
                            processedImages = props.data.imagetransforms.images_Transforms;
                        }

                        var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                        return <Carousel.Item>
                          
        <div className="property-box">
            <a href={`${checkptype}${item.slug}-${item.id}/`}>
            <div className="property-box__image">
            <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similar"attr={{ alt: props.address.address1+ ' - Anthony Pepe' }}
					imagetransformresult={processedImages} id={props.id}/>
            </div>
            </a>
            <div className="property-box__content">
                <h4>{item.display_address}</h4>
                <div className="price">
                    <span className="d-md-none">Guide Price</span> {'£' + item.price.toLocaleString()}
                </div>
                <small className="beds">
                {item.title}
                </small>
            </div>
        </div>
                        </Carousel.Item>
                    }) : ''}
                    {/* <Col lg="6">
                        <PropertyBox image={data.pr2.childImageSharp.fluid} title="Belmont Close, Totteridge, London, N20" price="£1,200,000" beds="4 bedroom detached house for sale" />
                    </Col> */}        
        </Carousel>
    )
    return (
        <div className="properties-boxes" data-aos="fade-up">
            <Container>
                <h2>{props.Title}</h2>
                {props.showsale == "true" && props.showrent == "true" && props.data &&
                    <>
                        <div className="property-actions">
                            <a href="/">Property for Sale in Harryingay</a>
                            <a href="/">Property to Rent in Harryingay</a>
                        </div>
                    </>
                }

                <Row className="d-none d-lg-flex">
                    {props.data && props.data.length > 0 ? props.data.map((item, key) => {
                         let processedImages = JSON.stringify({});
                         if (props.data?.imagetransforms?.images_Transforms) {
                             processedImages = props.data.imagetransforms.images_Transforms;
                         }
 
                        var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                        return <Col lg="6">
                    <div className="property-box">
                            <a href={`${checkptype}${item.slug}-${item.id}/`}>
                            <div className="property-box__image">
                            <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similar"attr={{ alt: props.address.address1+ ' - Anthony Pepe' }}
                                    imagetransformresult={processedImages} id={props.id}/>
                            </div>
                            </a>
                            <div className="property-box__content">
                                <h4>{item.display_address}</h4>
                                <div className="price">
                                    <span className="d-md-none">Guide Price</span> {'£' + item.price.toLocaleString()}
                                </div>
                                <small className="beds">
                                {item.title}
                                </small>
                            </div>
                        </div>
                        </Col>
                    }) : ''}
                    {/* <Col lg="6">
                        <PropertyBox image={data.pr2.childImageSharp.fluid} title="Belmont Close, Totteridge, London, N20" price="£1,200,000" beds="4 bedroom detached house for sale" />
                    </Col> */}
                </Row>
                <div className="d-block d-lg-none">
                    <Slider />
                </div>
            </Container>
        </div>
    )
}

export default PropertiesBoxes
