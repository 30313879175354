import React, { Component } from "react";
import { render } from "react-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [
  "//placekitten.com/1500/500",
  "//placekitten.com/4000/3000",
  "//placekitten.com/800/1200",
  "//placekitten.com/1500/1500"
];

class FloorplamModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    console.log('aaaaaaa',this.props.floorplanimages.length)
    return (
      <div>
        <a href="javascript:;" onClick={() => this.setState({ isOpen: true })}>
          Epc
        </a>

        {isOpen && (
          <Lightbox  
            enableZoom={true}
            mainSrc={this.props.floorplanimages[photoIndex]}
            nextSrc={this.props.floorplanimages.length > 1 && this.props.floorplanimages[(photoIndex + 1) % this.props.floorplanimages.length]}
            prevSrc={this.props.floorplanimages.length > 1 && this.props.floorplanimages[(photoIndex + this.props.floorplanimages.length - 1) % this.props.floorplanimages.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.props.floorplanimages.length - 1) % this.props.floorplanimages.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.floorplanimages.length
              })
            }
          />
        )}
      </div>
    );
  }
}

export default FloorplamModal