import React, {  } from 'react';
import { Col } from 'react-bootstrap';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ContactBox from '@components/contact-box/contact-box';


const SINGLE_TEAM = gql`
query GetTeamDetails ($CRM_Office_ID: String!){
  offices (where:{CRM_Office_ID:$CRM_Office_ID}) {
    Title
    URL
    Phone_Number
    CRM_Office_ID
    Team_Members {
      URL
        Name
        Phone
        Designation
        Image {
          url
        }
    }
  }
}
`;



function TeamDetails(props) {
  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { CRM_Office_ID: props.officecrmid }
  });
  if (loading) return <p>Loading ...</p>;
  
  // console.log('data ==>', data)
  
  return (
    <>
      {data && data.offices.map((node, key) => {
        return <>
		{node.Team_Members.map((team, key) => {
			return <>
			<Col md="6" lg="12">
				<ContactBox name={team.Name} phone={team.Phone} link="#" linkText="Video Call
" teamimage={`${team.Image && team.Image.url}`} />

			</Col>
			</>
		})}        
        </>
      })}
    </>
  )
}

export default TeamDetails
