import React, {useEffect} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import classNames from 'classnames'
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { useLocation } from "@reach/router"

/**
 * Assets
 */

 import './styles/_index.scss';

/**
 * Components
 */
import Video from '@components/video/video';
import { MapMarkerInfoBox } from '@components/map/map-marker';
import LocRatingMap from '@components/map/loc-rating-map';
import { Link } from '@reach/router';
import $ from "jquery";

const StaticWave = () => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const data = useStaticQuery(graphql`
        query {
        video: file(relativePath: { eq: "video3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        allStrapiOffices {
            edges {
              node {
                Title
                URL
                Address
                Latitude
                Longitude
              }
            }
        }
        }

    `);
    const location = useLocation();
    var rootpath = location.origin;
    // Get Map Details
    let mapItems = [];
    {data.allStrapiOffices.edges.map(({node}, key) => {
        if(node.Title !="Property Management") {
        let mapItem = {};
        mapItem['lat']  = node.Latitude;        
        mapItem['lng']  = node.Longitude;
        const mapMarkerImageShowFlag = 0;
        var link = "<a class='mapviewbtn' target='_parent' style='float:left;width:100%;color: #fff;font-weight:bold;margin:15px 0;background: #5D9272;padding: 8px;border-radius: 4px;text-align: center;' href="+rootpath+"/contact/our-offices/"+node.URL+"/>View Office</a>";
        var officetitle = "<span style='font-weight:bold;display: inline-block;margin-bottom: 5px;'>"+node.Title+"</span><br>";
        let mapItemHtml = MapMarkerInfoBox(officetitle + node.Address + link,  mapMarkerImageShowFlag);
        mapItem['html'] = mapItemHtml;
        mapItems.push( mapItem );
        }
    })};
    useEffect(() => {
        setTimeout(function(){ 
            $('.wave').addClass('banneranimation');
        }, 1000);
        setTimeout(function(){ 
            $('.wave').removeClass('banneranimation');
        }, 10000);
    });
    return (
        <div className="static-wave office-landing-map">
        <div  className={classNames('wave', {'': inView})} ref={ref}>
            <ScrollAnimation offset={100} animateIn='animate__animated animate__slideInLeft' animateOnce={true} duration={0.3} delay={300}>      
            <div className={classNames('wave__video', {'': inView})}>
                    <LocRatingMap data={mapItems} hidestations={true} autocenter={false}/>
                </div>
            </ScrollAnimation>
            </div>
        </div>
    )
}

export default StaticWave
