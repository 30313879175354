import React, { useState, useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import FsLightbox from 'fslightbox-react';
import $ from 'jquery'
import FloorplanModal from './floorplan-modal'
import EpcModal from './epc-modal'

/**
 * Assets
 */
import './styles/_index.scss';

const links = [
  {
    text: 'Details',
    url: '#details',
    isSpy: true
  },
  {
    text: 'Map',
    url: '#map',
    isSpy: true
  },
  {
    text: 'Video',
    url: '#video',
    isSpy: true
  },
  {
    text: 'Floorplans',
    url: '#'
  },
  {
    text: 'Brochure',
    url: '#'
  },
  {
    text: 'Epc',
    url: '#'
  }
];

function getOffset( el ) {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const StickyNav = (props) => {
  //console.log('eeeeeeeeeeeeee',props.epc)
  const scrollHandle = (event) => {
    event.preventDefault();
    const offestTop = getOffset(document.querySelector(event.target.getAttribute('href'))).top;
    const stickyHeight = document.querySelector('.sticky-nav').clientHeight;
    window.scrollTo({
      top: offestTop - stickyHeight,
      behavior: 'smooth'
    });
  }
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1 
    });


    const FloorplanImages = props.floorplan && props.floorplan.map(img => img.srcUrl);
    const EpcImages = props.epc && props.epc.map(img => img.srcUrl);
    useEffect(() => {

    if (typeof window !== "undefined") {
      $(window).scroll(function () {
          var sticky = $('.property-fixed-bar'),
              scroll = $(window).scrollTop();
    
          if (scroll >= 300) sticky.addClass('is-active');
          else sticky.removeClass('is-active');
      });
      }
    },[]);

    const openLighbox = (e) => { 
      e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: 1 
        });        
    }



  return (

    <>
              {props.floorplan && props.floorplan.length > 0 &&

    <FsLightbox
    toggler={lightboxController.toggler}
    sources={FloorplanImages}
    key={lightboxController.key}
    type='image'/>
              }
    <div className="sticky-nav">
      <Scrollspy
        offset={-120}
        componentTag={Nav}
        currentClassName="is-actives"
      >
          {links.map((link, ind) => (
            <>
            {link.text === 'Video'&& props.virtual_tour && props.virtual_tour.length > 0 &&
            <a href={props.virtual_tour} className="nav-link" target="_blank">
                    {link.text} 
            </a>
            }
                                {link.text === 'Floorplans' &&

                                <>
          {props.floorplan && props.floorplan.length > 0 &&
                        <>
                       
                        <FloorplanModal floorplanimages={FloorplanImages}/>
                        </>
                      }
                      </>
                    }
                    {link.text === 'Epc' &&

<>
                    {props.epc && props.epc.length > 0 &&
                        <>
                       
                        <EpcModal floorplanimages={EpcImages}/>
                        </>
                      }
                      </>
                      }
                    {link.text == 'Brochure' && props.brochure && props.brochure.length > 0 &&
                    <>
                    <a href={props.brochure} className="nav-link" target="_blank">
                    {link.text} 
                    </a>
                    </>
                  }
{link.text !== 'Video' && link.text !== 'Brochure' && link.text !== 'Floorplans' && link.text !== 'Epc' &&
                      <Nav.Link key={ind} href={link.url} onClick={link.isSpy ? scrollHandle : ''}>{link.text}</Nav.Link>
                    }

 
          </>         
          ))}

      </Scrollspy>
    </div>
    </>
  )
}

export default StickyNav;
