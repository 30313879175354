import React, { useEffect } from 'react';
import Img from 'gatsby-image';

/**
 * Assets
 */
import './styles/_index.scss';

const ImgTextBox = ({image, children, alt}) => {




    return (
        <div className="img-text-box" data-aos="fade-up" data-aos-duration="1000">
            <div className="image">
                <Img fluid={image} alt={alt}/>
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    )
}

export default ImgTextBox
