import React from 'react'
import { Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import TableData from '@components/table-data/table-data';

const data = {
    headings: ['Service','Fully Managed','Rent Collect','Only Let'],
    cells: [
        ['Visit property and advise on potential rents', "✓", "✓", "✓"],
        ['Market the property to the widest audience', "✓", "✓", "✓"],
        ['Arrange appointments and accompany viewings', "✓", "✓", "✓"],
        ['Conduct references and ID checks on tenants', "✓", "✓", "✓"],
        ['Draw up a legally correct Tenancy Agreement', "✓", "✓", "✓"],
        ['Negotiate tenancy renewals on the best possible terms', "✓", "✓", "✓"],
        ['Carry out the duties of an agent according to the service level selected and listed in the Terms of Business for Landlords', "✓", "✓", "✓"],
        ['Arrange gas safety', "✓", "✓", "✓"],
        ['Help identify furnishing that comply with Fire Safety legislation', "✓", "✓", "✓"],
        ['Receipt rent and chase late payment', "✓", "✓", false],
        ['Arrange for an inventory and Schedule of Condition of the property and its contents to be drawn up', "✓", false, false],
        ['Collect and register the deposit (where legislation dictates)	', "✓", false, false],
        ['Manage the transfer of utilities', "✓", false, false],
        ['Act as Stakeholder/Agent in any damage disputes', "✓", false, false],
        ['Conduct management visits and manage tenancy issues', "✓", false, false],
        ['Handle maintenance issues using approved contractors', "✓", false, false],
        ['Payment of property specific bill', "✓", false, false],
        ['Check out the tenant and check the inventory and Schedule of Condition and identify any potential Security Deposit claims', "✓", false, false],
    ]
}

const StaticTable = (props) => {
    return (
        <div className="static-table">
            <Container>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.Content}/>
            </Container>
        </div>
    )
}

export default StaticTable
