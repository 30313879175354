import React, { useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import { Link } from "gatsby"
import MemberForm from "@components/forms/member-form"
import Modal from 'react-bootstrap/Modal'
/**
 * Assets
 */
import "slick-carousel/slick/slick.css";
import './styles/_index.scss';

/**
 * Components
 */
import PersonVideo from '@components/person-video/person-video';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import PlayIcon from '@components/play-icon/play-icon';
import ReactPlayer from 'react-player';
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
const StaticPersonVideo = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [teamid, setteamid] = useState(false);
  
    const [showVideo, setShowVideo] = React.useState(false);
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const [videoindex, setVideoindex] = React.useState('default');
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const vidRef = useRef(null);
    const [isPlay,setPlay] = React.useState(false);
      const playVideo = (video_index, video_id) => {
        setVideoindex(video_index)   
        setShowVideo(true)
      }
      const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': url,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
      }
    const handleShow = (event) => {
      setteamid(event);
      setShow(true);
  }
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };
    return (
        <div className="static-person-video team-section">
            <Container>
                <h2>Behind a brilliant service, is a brilliant team. <br/>Meet our Harringay team.</h2>
                <div className="slider-holder">
                    <Slider {...settings}>
                    {props.data.edges.map(({node}, index) => {
                        if(node.Embed_Video_URL !=null && node.Embed_Video_URL != '') {
                            var videoid = getVideoId(node.Embed_Video_URL);
                        }  
                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Image_Transforms) {
                            processedImages = node.imagetransforms.Image_Transforms;
                        }
                        const image_url = node.Image && node.Image.internal.description ? node.Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
                        return <>
                        <div>
                        <div className="person-video">
                        <div className="vide-wrapper video-box">
                            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="team.Image.slideimg"attr={{ alt: node.Name+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={node.id}/>
                            {showVideo && videoindex== index &&
                            <>
                            <div className="videoBTNClose">
                                <a onClick={e => {setPlay(false);setShowVideo(false);setVideoindex('default');}}></a>
                            </div>
                            <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(node.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false);setVideoindex('default'); }} width='100%' height='100%' />
                                    </>
                            }
                            </div>
                                <h4>{node.Name}</h4>
                                <h6>{node.Designation}</h6>
                                <p><a href={`tel:${node.Phone}`}>{node.Phone}</a>  / <a href="javascript:;" onClick={ () => handleShow(node.strapiId)}>Email</a>

                                {node.Calendly_Link && 
                                <>
                                /  <a href={node.Calendly_Link}>Video Call</a>
                                </>
                                }
                                </p>
                                {node.Embed_Video_URL !=null && node.Embed_Video_URL != '' && videoindex != index &&
                                <a href="javascript:;" onClick = { (e) => {playVideo(index, videoid.id)}}>
                                <PlayIcon />
                                </a>
                            }
                                </div>
                                {teamid && teamid == node.strapiId &&
      <>
        <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form">

          <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100">CONTACT - {node.Name}
            </Modal.Title>
              </Modal.Header>
        <Modal.Body>
        <MemberForm to_email_id={node.Email} formName={`Contact Person`} />
        </Modal.Body>
      </Modal>
      </>
        }
                        </div>
                        </>
                    })}
                        
                    </Slider>
                </div>
            </Container>
        </div>
    )
}

export default StaticPersonVideo
